import { Component } from "react";

const moment = require("moment");

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timeframe: "Hourly"
    }
  }

  handleTimeframeChange = e => {
    this.setState({
      timeframe: e.target.value
    });
  }

  render() {
    return (
      <div className="columns m-2">
        <div className="column is-4">
          <div className="box notification is-primary has-text-centered">
            <h4 className="is-size-4">NIFTY{moment(this.props.settings.niftyExpiry).format("MMMYYYY").toUpperCase()}FUT</h4>
            <hr />
            <table className="table is-striped is-hoverable is-fullwidth is-bordered">
              <tbody>
                <tr>
                  <th>CMP</th>
                  <td className={this.props.niftyData.cmp < 0 ? "has-text-danger has-text-weight-bold" : "has-text-success has-text-weight-bold"}>
                    {this.props.niftyData.cmp}
                  </td>
                </tr>
                <tr>
                  <th>Net Change</th>
                  <td className={this.props.niftyData.netChange < 0 ? "has-text-danger has-text-weight-bold" : "has-text-success has-text-weight-bold"}>
                    {this.props.niftyData.netChange}
                  </td>
                </tr>
                <tr>
                  <th>% Change</th>
                  <td className={this.props.niftyData.pctChange < 0 ? "has-text-danger has-text-weight-bold" : "has-text-success has-text-weight-bold"}>
                    {this.props.niftyData.pctChange} %
                  </td>
                </tr>
                <tr>
                  <th>VWAP</th>
                  <td className={this.props.niftyData.vwap < 0 ? "has-text-danger has-text-weight-bold" : "has-text-success has-text-weight-bold"}>
                    {this.props.niftyData.vwap}
                  </td>
                </tr>
                <tr>
                  <th>OI</th>
                  <td className={this.props.niftyData.oi < 0 ? "has-text-danger has-text-weight-bold" : "has-text-success has-text-weight-bold"}>
                    {this.props.niftyData.oi}
                  </td>
                </tr>
                <tr>
                  <th>OI Day High</th>
                  <td className={this.props.niftyData.oiDayHigh < 0 ? "has-text-danger has-text-weight-bold" : "has-text-success has-text-weight-bold"}>
                    {this.props.niftyData.oiDayHigh}
                  </td>
                </tr>
                <tr>
                  <th>OI Day Low</th>
                  <td className={this.props.niftyData.oiDayLow < 0 ? "has-text-danger has-text-weight-bold" : "has-text-success has-text-weight-bold"}>
                    {this.props.niftyData.oiDayLow}
                  </td>
                </tr>
                <tr>
                  <th>Net Demand/Supply</th>
                  <td className={this.props.niftyData.netDemand < 0 ? "has-text-danger has-text-weight-bold" : "has-text-success has-text-weight-bold"}>
                    {this.props.niftyData.netDemand}
                  </td>
                </tr>
                <tr>
                  <th>Ratio</th>
                  <td className={this.props.niftyData.ratio < 0 ? "has-text-danger has-text-weight-bold" : "has-text-success has-text-weight-bold"}>
                    {this.props.niftyData.ratio}
                  </td>
                </tr>
                <tr>
                  <th>Lot</th>
                  <td className={this.props.niftyData.lot < 0 ? "has-text-danger has-text-weight-bold" : "has-text-success has-text-weight-bold"}>
                    {this.props.niftyData.lot}
                  </td>
                </tr>
                <tr>
                  <th>Open</th>
                  <td className="has-text-weight-bold has-text-link">
                    {this.props.niftyData.open}
                  </td>
                </tr>
                <tr>
                  <th>High</th>
                  <td className="has-text-weight-bold has-text-link">
                    {this.props.niftyData.high}
                  </td>
                </tr>
                <tr>
                  <th>Low</th>
                  <td className="has-text-weight-bold has-text-link">
                    {this.props.niftyData.low}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="column is-4">
          <div className="box notification is-warning has-text-centered">
            <h4 className="is-size-4">BANKNIFTY{moment(this.props.settings.bankniftyExpiry).format("MMMYYYY").toUpperCase()}FUT</h4>
            <hr />
            <table className="table is-striped is-hoverable is-fullwidth is-bordered">
              <tbody>
                <tr>
                  <th>CMP</th>
                  <td className={this.props.bankniftyData.cmp < 0 ? "has-text-danger has-text-weight-bold" : "has-text-success has-text-weight-bold"}>
                    {this.props.bankniftyData.cmp}
                  </td>
                </tr>
                <tr>
                  <th>Net Change</th>
                  <td className={this.props.bankniftyData.netChange < 0 ? "has-text-danger has-text-weight-bold" : "has-text-success has-text-weight-bold"}>
                    {this.props.bankniftyData.netChange}
                  </td>
                </tr>
                <tr>
                  <th>% Change</th>
                  <td className={this.props.bankniftyData.pctChange < 0 ? "has-text-danger has-text-weight-bold" : "has-text-success has-text-weight-bold"}>
                    {this.props.bankniftyData.pctChange} %
                  </td>
                </tr>
                <tr>
                  <th>VWAP</th>
                  <td className={this.props.bankniftyData.vwap < 0 ? "has-text-danger has-text-weight-bold" : "has-text-success has-text-weight-bold"}>
                    {this.props.bankniftyData.vwap}
                  </td>
                </tr>
                <tr>
                  <th>OI</th>
                  <td className={this.props.bankniftyData.oi < 0 ? "has-text-danger has-text-weight-bold" : "has-text-success has-text-weight-bold"}>
                    {this.props.bankniftyData.oi}
                  </td>
                </tr>
                <tr>
                  <th>OI Day High</th>
                  <td className={this.props.bankniftyData.oiDayHigh < 0 ? "has-text-danger has-text-weight-bold" : "has-text-success has-text-weight-bold"}>
                    {this.props.bankniftyData.oiDayHigh}
                  </td>
                </tr>
                <tr>
                  <th>OI Day Low</th>
                  <td className={this.props.bankniftyData.oiDayLow < 0 ? "has-text-danger has-text-weight-bold" : "has-text-success has-text-weight-bold"}>
                    {this.props.bankniftyData.oiDayLow}
                  </td>
                </tr>
                <tr>
                  <th>Net Demand/Supply</th>
                  <td className={this.props.bankniftyData.netDemand < 0 ? "has-text-danger has-text-weight-bold" : "has-text-success has-text-weight-bold"}>
                    {this.props.bankniftyData.netDemand}
                  </td>
                </tr>
                <tr>
                  <th>Ratio</th>
                  <td className={this.props.bankniftyData.ratio < 0 ? "has-text-danger has-text-weight-bold" : "has-text-success has-text-weight-bold"}>
                    {this.props.bankniftyData.ratio}
                  </td>
                </tr>
                <tr>
                  <th>Lot</th>
                  <td className={this.props.bankniftyData.lot < 0 ? "has-text-danger has-text-weight-bold" : "has-text-success has-text-weight-bold"}>
                    {this.props.bankniftyData.lot}
                  </td>
                </tr>
                <tr>
                  <th>Open</th>
                  <td className="has-text-weight-bold has-text-link">
                    {this.props.bankniftyData.open}
                  </td>
                </tr>
                <tr>
                  <th>High</th>
                  <td className="has-text-weight-bold has-text-link">
                    {this.props.bankniftyData.high}
                  </td>
                </tr>
                <tr>
                  <th>Low</th>
                  <td className="has-text-weight-bold has-text-link">
                    {this.props.bankniftyData.low}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="column is-4">
          <div className="box notification is-info has-text-centered">
            <h4 className="is-size-4">FINNIFTY{moment(this.props.settings.finniftyExpiry).format("MMMYYYY").toUpperCase()}FUT</h4>
            <hr />
            <table className="table is-striped is-hoverable is-fullwidth is-bordered">
              <tbody>
                <tr>
                  <th>CMP</th>
                  <td className={this.props.finniftyData.cmp < 0 ? "has-text-danger has-text-weight-bold" : "has-text-success has-text-weight-bold"}>
                    {this.props.finniftyData.cmp}
                  </td>
                </tr>
                <tr>
                  <th>Net Change</th>
                  <td className={this.props.finniftyData.netChange < 0 ? "has-text-danger has-text-weight-bold" : "has-text-success has-text-weight-bold"}>
                    {this.props.finniftyData.netChange}
                  </td>
                </tr>
                <tr>
                  <th>% Change</th>
                  <td className={this.props.finniftyData.pctChange < 0 ? "has-text-danger has-text-weight-bold" : "has-text-success has-text-weight-bold"}>
                    {this.props.finniftyData.pctChange} %
                  </td>
                </tr>
                <tr>
                  <th>VWAP</th>
                  <td className={this.props.finniftyData.vwap < 0 ? "has-text-danger has-text-weight-bold" : "has-text-success has-text-weight-bold"}>
                    {this.props.finniftyData.vwap}
                  </td>
                </tr>
                <tr>
                  <th>OI</th>
                  <td className={this.props.finniftyData.oi < 0 ? "has-text-danger has-text-weight-bold" : "has-text-success has-text-weight-bold"}>
                    {this.props.finniftyData.oi}
                  </td>
                </tr>
                <tr>
                  <th>OI Day High</th>
                  <td className={this.props.finniftyData.oiDayHigh < 0 ? "has-text-danger has-text-weight-bold" : "has-text-success has-text-weight-bold"}>
                    {this.props.finniftyData.oiDayHigh}
                  </td>
                </tr>
                <tr>
                  <th>OI Day Low</th>
                  <td className={this.props.finniftyData.oiDayLow < 0 ? "has-text-danger has-text-weight-bold" : "has-text-success has-text-weight-bold"}>
                    {this.props.finniftyData.oiDayLow}
                  </td>
                </tr>
                <tr>
                  <th>Net Demand/Supply</th>
                  <td className={this.props.finniftyData.netDemand < 0 ? "has-text-danger has-text-weight-bold" : "has-text-success has-text-weight-bold"}>
                    {this.props.finniftyData.netDemand}
                  </td>
                </tr>
                <tr>
                  <th>Ratio</th>
                  <td className={this.props.finniftyData.ratio < 0 ? "has-text-danger has-text-weight-bold" : "has-text-success has-text-weight-bold"}>
                    {this.props.finniftyData.ratio}
                  </td>
                </tr>
                <tr>
                  <th>Lot</th>
                  <td className={this.props.finniftyData.lot < 0 ? "has-text-danger has-text-weight-bold" : "has-text-success has-text-weight-bold"}>
                    {this.props.finniftyData.lot}
                  </td>
                </tr>
                <tr>
                  <th>Open</th>
                  <td className="has-text-weight-bold has-text-link">
                    {this.props.finniftyData.open}
                  </td>
                </tr>
                <tr>
                  <th>High</th>
                  <td className="has-text-weight-bold has-text-link">
                    {this.props.finniftyData.high}
                  </td>
                </tr>
                <tr>
                  <th>Low</th>
                  <td className="has-text-weight-bold has-text-link">
                    {this.props.finniftyData.low}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default Dashboard;
