import { Component } from "react";

import Navbar from "./navbar.jsx";
import Settings from "./settings.jsx";
import Dashboard from "./dashboard.jsx";

class MainPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: "dashboard"
    }
  }

  changePage = page => {
    console.log(`Changing to ${page}`);

    this.setState({
      currentPage: page
    });
  }

  render() {
    let page;

    if (this.state.currentPage === "dashboard") {
      page = <Dashboard
        settings={this.props.settings}
        niftyData={this.props.niftyData}
        bankniftyData={this.props.bankniftyData}
        finniftyData={this.props.finniftyData}
      />

    } else if (this.state.currentPage === "settings") {
      page = <Settings
        settings={this.props.settings}
        handleSettingsSave={this.props.handleSettingsSave}
        handleSettingsChange={this.props.handleSettingsChange}
      />
    }

    return (
      <div>
        <Navbar
          username={this.props.username}
          handleLogout={this.props.handleLogout}
          changePage={this.changePage}
        />
        {page}
      </div>
    );
  }
}

export default MainPage;
