import "./App.css";
import Cookies from "js-cookie";
import { Component } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Components
import MainPage from "./components/mainPage.jsx";
import LoginForm from "./components/loginForm.jsx";

toast.configure()

const options = {
  position: "bottom-right",
  pauseOnHover: true,
  closeOnClick: true,
  theme: "dark",
  autoClose: 2500
}

const qs = require("query-string");
const moment = require("moment");
// const struct = require("cstruct");
// const Buffer = require("buffer");
// const bufferify = require("json-bufferify");
// let KiteTicker = require("kiteconnect").KiteTicker;

class App extends Component {
  constructor(props) {
    super(props);

    // this.MarketData = struct`
    //   char exchange;
    //   uint32 token;
    //   uint32 ltp;
    //   uint32 ltt;
    //   uint32 ltq;
    //   uint32 volume;
    //   uint32 best_bid_price;
    //   uint32 best_bid_quantity;
    //   float32 total_buy_quantity;
    //   float32 total_sell_quantity;
    //   uint32 atp;
    //   uint32 exchange_time_stamp;
    //   uint32 open;
    //   uint32 high;
    //   uint32 low;
    //   uint32 close;
    //   uint32 yearly_high;
    //   uint32 yearly_low;
    // `

    console.log(process.env.NODE_ENV);
    this.URL = process.env.NODE_ENV === "production" ? "" : "http://localhost:8000";

    console.log(this.URL);

    this.NIFTY_LOT_SIZE = 50;
    this.BANKNIFTY_LOT_SIZE = 25;
    this.FINNIFTY_LOT_SIZE = 40;

    this.state = {
      loggedIn: localStorage.getItem("jwt-token") ? true : false,
      username: "",
      apiKey: "",
      accessToken: "",
      ticker: null,
      tokens: {},
      settings: {
        niftyExpiry: "01-01-2000",
        bankniftyExpiry: "01-01-2000",
        finniftyExpiry: "01-01-2000"
      },
      niftyData: {
        cmp: 0,
        netChange: 0,
        pctChange: 0,
        vwap: 0,
        oi: 0,
        oiDayHigh: 0,
        oiDayLow: 0,
        netDemand: 0,
        ratio: 0,
        lot: 0,
        open: 0,
        high: 0,
        low: 0
      },
      bankniftyData: {
        cmp: 0,
        netChange: 0,
        pctChange: 0,
        vwap: 0,
        oi: 0,
        oiDayHigh: 0,
        oiDayLow: 0,
        netDemand: 0,
        ratio: 0,
        lot: 0,
        open: 0,
        high: 0,
        low: 0
      },
      finniftyData: {
        cmp: 0,
        netChange: 0,
        pctChange: 0,
        vwap: 0,
        oi: 0,
        oiDayHigh: 0,
        oiDayLow: 0,
        netDemand: 0,
        ratio: 0,
        lot: 0,
        open: 0,
        high: 0,
        low: 0
      },
    }
  }

  request = async (route, method, body=null, logoutOnElse=true, msgOnSuccess=true) => {
    let res;

    if (method === "POST") {
      res = await fetch(`${this.URL}/api/${route}`, {
        method: method,
        headers: {
          "Authorization": `jwt-token ${localStorage.getItem("jwt-token")}`,
          "X-CSRFToken": this.getCsrfCookie()
        },
        body: JSON.stringify(body)
      });
    } else {
      res = await fetch(`${this.URL}/api/${route}`, {
        method: method,
        headers: {
          "Authorization": `jwt-token ${localStorage.getItem("jwt-token")}`,
          "X-CSRFToken": this.getCsrfCookie()
        }
      });
    }

    const json = await res.json();
    console.log(json);

    if (json.type === "success") {
      if (msgOnSuccess) {
        toast.success(`Success: ${json.message}`, options);
      }

      return json

    } else if (json.type === "info") {
      if (msgOnSuccess) {
        toast.info(`Success: ${json.message}`, options);
      }

      return json

    } else if (json.type === "error") {
      toast.error(`Error: ${json.message}`, options);

      return json

    } else if (json.type === "generateToken") {
      console.log(`Redirecting to ${json.url}`);
      window.location = json.url;

      return json

    } else {
      toast.error(`${json.type}: ${json.message}`, options);
      if (logoutOnElse) {
        toast.error("Logged out !", options);
        this.handleLogout();
      }

      return json
    }
  }

  componentDidMount = async () => {
    if (this.state.loggedIn) {
      const json = await this.request("current-user/", "GET");

      if (json.type === "success") {
        this.fetchBrokerToken();
      }
    }
  }

  getCsrfCookie = () => {
    return Cookies.get("csrftoken");
  }

  handleLogin = async data => {
    console.log(data);
    const json = await this.request("login/", "POST", data, false);

    if (json.type === "success") {
      localStorage.setItem("jwt-token", json.jwtToken);
      this.setState({loggedIn: true});

      const user_json = await this.request("current-user/", "GET");

      if (user_json.type === "success") {
        this.setState({
          username: user_json.username
        });

        this.fetchBrokerToken();
      }
    }
  }

  handleLogout = () => {
    localStorage.removeItem("jwt-token");
    this.setState({loggedIn: false});
  }

  fetchBrokerToken = async () => {
    let parsed = qs.parse(window.location.search);
    let reqToken = parsed.request_token;

    console.log(reqToken);

    if (reqToken) {
      await this.request("set-access-token/", "POST", {requestToken: reqToken});
      window.location.search = "";
    }

    if (this.state.loggedIn) {
      const json = await this.request("broker-token/", "GET");

      if (json.type === "success") {
        this.setState({
          username: json.username,
          apiKey: json.api_key,
          accessToken: json.access_token
        })
        await this.getSettings();
        await this.connectWS();
      }
    }
  }

  fetchInsTokens = async insList => {
    const json = await this.request(`ins-token/?instruments=${insList}`, "GET");

    if (json.type === "success") {
      this.setState({
        tokens: json.tokens
      });
    }
  }

  getSettings = async () => {
    const json = await this.request("settings/", "GET");

    if (json.type === "success") {
      this.setState({
        settings: json.settings
      });
    }
  }

  handleSettingsChange = e => {
    const name = e.target.name;
    const val = e.target.value;

    this.setState(prevState => {
      const newState = { ...prevState };
      const newSettings = { ...newState.settings };

      newSettings[name] = val;
      newState.settings = newSettings;

      return newState;
    });
  }

  handleSettingsSave = async () => {
    await this.request("settings/save/", "POST", this.state.settings);
    await this.getSettings();
    await this.restartWS();
  }

  connectWS = async () => {
    console.log("Connecting to Kite WebSocket !");

    const niftyExpiryStr = moment(this.state.settings.niftyExpiry).format("YYMMM").toUpperCase();
    const bankniftyExpiryStr = moment(this.state.settings.bankniftyExpiry).format("YYMMM").toUpperCase();
    const finniftyExpiryStr = moment(this.state.settings.finniftyExpiry).format("YYMMM").toUpperCase();

    console.log(niftyExpiryStr);
    console.log(bankniftyExpiryStr);
    console.log(finniftyExpiryStr);

    await this.fetchInsTokens(`NIFTY${niftyExpiryStr}FUT BANKNIFTY${bankniftyExpiryStr}FUT FINNIFTY${finniftyExpiryStr}FUT`);

    const ws = new WebSocket(`wss://ws.kite.trade?api_key=${this.state.apiKey}&access_token=${this.state.accessToken}`);
    ws.binaryType = "arraybuffer";

    console.log(ws);

    ws.onopen = e => {
      console.log(e);
      // Object.values(this.state.tokens).map(token => {
      //   console.log(token);
      //
      //   this.state.ws.send(JSON.stringify({
      //     "a": "subscribe",
      //     "v": [token]
      //   }))
      // })

      this.state.ws.send(JSON.stringify({
        "a": "subscribe",
        "v": Object.values(this.state.tokens)
      }))
  		this.state.ws.send(JSON.stringify({
        "a": "mode",
        "v": ["full", Object.values(this.state.tokens)]
      }));
    }
    ws.onmessage = async e => {
      if (e.data.byteLength > 2) {
				let d = this.parseBinary(e.data);
        console.log(d);

        const niftyExpiryStr = moment(this.state.settings.niftyExpiry).format("YYMMM").toUpperCase();
        const bankniftyExpiryStr = moment(this.state.settings.bankniftyExpiry).format("YYMMM").toUpperCase();
        const finniftyExpiryStr = moment(this.state.settings.finniftyExpiry).format("YYMMM").toUpperCase();

        let niftyData = null;
        let bankniftyData = null;
        let finniftyData = null;

        d.map(_d => {
          if (_d.instrument_token === this.state.tokens[`NIFTY${niftyExpiryStr}FUT`]) {
            niftyData = _d;

          } else if (_d.instrument_token === this.state.tokens[`BANKNIFTY${bankniftyExpiryStr}FUT`]) {
            bankniftyData = _d;

          } else if (_d.instrument_token === this.state.tokens[`FINNIFTY${finniftyExpiryStr}FUT`]) {
            finniftyData = _d;
          }
        });

        if (niftyData) {
          this.setState({
            niftyData: {
              cmp: niftyData.last_price,
              netChange: ((niftyData.change / 100) * niftyData.ohlc.close).toFixed(2),
              pctChange: niftyData.change.toFixed(2),
              vwap: (niftyData.last_price - niftyData.average_traded_price).toFixed(2),
              oi: niftyData.oi,
              oiDayHigh: niftyData.oi_day_high,
              oiDayLow: niftyData.oi_day_low,
              netDemand: (niftyData.total_buy_quantity - niftyData.total_sell_quantity).toFixed(2),
              ratio: (niftyData.total_buy_quantity / niftyData.total_sell_quantity).toFixed(2),
              lot: ((niftyData.total_buy_quantity - niftyData.total_sell_quantity) / this.NIFTY_LOT_SIZE).toFixed(2),
              open: niftyData.ohlc.open,
              high: niftyData.ohlc.high,
              low: niftyData.ohlc.low
            }
          })
        }

        if (bankniftyData) {
          this.setState({
            bankniftyData: {
              cmp: bankniftyData.last_price,
              netChange: ((bankniftyData.change / 100) * bankniftyData.ohlc.close).toFixed(2),
              pctChange: bankniftyData.change.toFixed(2),
              vwap: (bankniftyData.last_price - bankniftyData.average_traded_price).toFixed(2),
              oi: bankniftyData.oi,
              oiDayHigh: bankniftyData.oi_day_high,
              oiDayLow: bankniftyData.oi_day_low,
              netDemand: (bankniftyData.total_buy_quantity - bankniftyData.total_sell_quantity).toFixed(2),
              ratio: (bankniftyData.total_buy_quantity / bankniftyData.total_sell_quantity).toFixed(2),
              lot: ((bankniftyData.total_buy_quantity - bankniftyData.total_sell_quantity) / this.BANKNIFTY_LOT_SIZE).toFixed(2),
              open: bankniftyData.ohlc.open,
              high: bankniftyData.ohlc.high,
              low: bankniftyData.ohlc.low
            }
          });
        }

        if (finniftyData) {
          this.setState({
            finniftyData: {
              cmp: finniftyData.last_price,
              netChange: ((finniftyData.change / 100) * finniftyData.ohlc.close).toFixed(2),
              pctChange: finniftyData.change.toFixed(2),
              vwap: (finniftyData.last_price - finniftyData.average_traded_price).toFixed(2),
              oi: finniftyData.oi,
              oiDayHigh: finniftyData.oi_day_high,
              oiDayLow: finniftyData.oi_day_low,
              netDemand: (finniftyData.total_buy_quantity - finniftyData.total_sell_quantity).toFixed(2),
              ratio: (finniftyData.total_buy_quantity / finniftyData.total_sell_quantity).toFixed(2),
              lot: ((finniftyData.total_buy_quantity - finniftyData.total_sell_quantity) / this.FINNIFTY_LOT_SIZE).toFixed(2),
              open: finniftyData.ohlc.open,
              high: finniftyData.ohlc.high,
              low: finniftyData.ohlc.low
            }
          });
        }
			}
    }
    ws.onerror = e => {
      console.log(e);
    }
    ws.onclose = e => {
      console.log(e);
    }

    this.setState({
      ws: ws
    });
  }

  restartWS = async () => {
    console.log("Restarting Kite WebSocket !");

    const niftyExpiryStr = moment(this.state.settings.niftyExpiry).format("YYMMM").toUpperCase();
    const bankniftyExpiryStr = moment(this.state.settings.bankniftyExpiry).format("YYMMM").toUpperCase();
    const finniftyExpiryStr = moment(this.state.settings.finniftyExpiry).format("YYMMM").toUpperCase();

    console.log(niftyExpiryStr);
    console.log(bankniftyExpiryStr);
    console.log(finniftyExpiryStr);

    await this.fetchInsTokens(`NIFTY${niftyExpiryStr}FUT BANKNIFTY${bankniftyExpiryStr}FUT FINNIFTY${finniftyExpiryStr}FUT`);
    await this.state.ws.close();

    const ws = new WebSocket(`wss://ws.kite.trade?api_key=${this.state.apiKey}&access_token=${this.state.accessToken}`);
    ws.binaryType = "arraybuffer";

    console.log(ws);

    ws.onopen = e => {
      console.log(e);
      // Object.values(this.state.tokens).map(token => {
      //   console.log(token);
      //
      //   this.state.ws.send(JSON.stringify({
      //     "a": "subscribe",
      //     "v": [token]
      //   }))
      // })

      this.state.ws.send(JSON.stringify({
        "a": "subscribe",
        "v": Object.values(this.state.tokens)
      }))
  		this.state.ws.send(JSON.stringify({
        "a": "mode",
        "v": ["full", Object.values(this.state.tokens)]
      }));
    }
    ws.onmessage = async e => {
      if (e.data.byteLength > 2) {
				let d = this.parseBinary(e.data);
        console.log(d);

        const niftyExpiryStr = moment(this.state.settings.niftyExpiry).format("YYMMM").toUpperCase();
        const bankniftyExpiryStr = moment(this.state.settings.bankniftyExpiry).format("YYMMM").toUpperCase();
        const finniftyExpiryStr = moment(this.state.settings.finniftyExpiry).format("YYMMM").toUpperCase();

        let niftyData = null;
        let bankniftyData = null;
        let finniftyData = null;

        d.map(_d => {
          if (_d.instrument_token === this.state.tokens[`NIFTY${niftyExpiryStr}FUT`]) {
            niftyData = _d;

          } else if (_d.instrument_token === this.state.tokens[`BANKNIFTY${bankniftyExpiryStr}FUT`]) {
            bankniftyData = _d;

          } else if (_d.instrument_token === this.state.tokens[`FINNIFTY${finniftyExpiryStr}FUT`]) {
            finniftyData = _d;
          }
        });

        if (niftyData) {
          this.setState({
            niftyData: {
              cmp: niftyData.last_price,
              netChange: ((niftyData.change / 100) * niftyData.ohlc.close).toFixed(2),
              pctChange: niftyData.change.toFixed(2),
              vwap: (niftyData.last_price - niftyData.average_traded_price).toFixed(2),
              oi: niftyData.oi,
              oiDayHigh: niftyData.oi_day_high,
              oiDayLow: niftyData.oi_day_low,
              netDemand: (niftyData.total_buy_quantity - niftyData.total_sell_quantity).toFixed(2),
              ratio: (niftyData.total_buy_quantity / niftyData.total_sell_quantity).toFixed(2),
              lot: ((niftyData.total_buy_quantity - niftyData.total_sell_quantity) / this.NIFTY_LOT_SIZE).toFixed(2),
              open: niftyData.ohlc.open,
              high: niftyData.ohlc.high,
              low: niftyData.ohlc.low
            }
          })
        }

        if (bankniftyData) {
          this.setState({
            bankniftyData: {
              cmp: bankniftyData.last_price,
              netChange: ((bankniftyData.change / 100) * bankniftyData.ohlc.close).toFixed(2),
              pctChange: bankniftyData.change.toFixed(2),
              vwap: (bankniftyData.last_price - bankniftyData.average_traded_price).toFixed(2),
              oi: bankniftyData.oi,
              oiDayHigh: bankniftyData.oi_day_high,
              oiDayLow: bankniftyData.oi_day_low,
              netDemand: (bankniftyData.total_buy_quantity - bankniftyData.total_sell_quantity).toFixed(2),
              ratio: (bankniftyData.total_buy_quantity / bankniftyData.total_sell_quantity).toFixed(2),
              lot: ((bankniftyData.total_buy_quantity - bankniftyData.total_sell_quantity) / this.BANKNIFTY_LOT_SIZE).toFixed(2),
              open: bankniftyData.ohlc.open,
              high: bankniftyData.ohlc.high,
              low: bankniftyData.ohlc.low
            }
          });
        }

        if (finniftyData) {
          this.setState({
            finniftyData: {
              cmp: finniftyData.last_price,
              netChange: ((finniftyData.change / 100) * finniftyData.ohlc.close).toFixed(2),
              pctChange: finniftyData.change.toFixed(2),
              vwap: (finniftyData.last_price - finniftyData.average_traded_price).toFixed(2),
              oi: finniftyData.oi,
              oiDayHigh: finniftyData.oi_day_high,
              oiDayLow: finniftyData.oi_day_low,
              netDemand: (finniftyData.total_buy_quantity - finniftyData.total_sell_quantity).toFixed(2),
              ratio: (finniftyData.total_buy_quantity / finniftyData.total_sell_quantity).toFixed(2),
              lot: ((finniftyData.total_buy_quantity - finniftyData.total_sell_quantity) / this.FINNIFTY_LOT_SIZE).toFixed(2),
              open: finniftyData.ohlc.open,
              high: finniftyData.ohlc.high,
              low: finniftyData.ohlc.low
            }
          });
        }
			}
    }
    ws.onerror = e => {
      console.log(e);
    }
    ws.onclose = e => {
      console.log(e);
    }

    this.setState({
      ws: ws
    });
  }

  // WS Functions
  parseBinary = binpacks => {
		let packets = this.splitPackets(binpacks);
  	let ticks = [];

		for (let n = 0; n < packets.length; n++) {
			let bin = packets[n];
			let instrument_token = this.buf2long(bin.slice(0, 4));
			let segment = instrument_token & 0xff;

			let tradable = true;
			if (segment === 9) tradable = false;

			// Add price divisor based on segment
			let divisor = 100.0;
			// if (segment === NseCD) {
			// 	divisor = 10000000.0;
      //
			// } else if (segment == BseCD) {
			// 	divisor = 10000.0;
			// }

			// Parse LTP
			if (bin.byteLength === 8) {
				ticks.push({
					tradable: tradable,
					mode: "ltp",
					instrument_token: instrument_token,
					last_price: this.buf2long(bin.slice(4, 8)) / divisor
				});

			// Parse indices quote and full mode
			} else if (bin.byteLength === 28 || bin.byteLength === 32) {
				let mode = "quote";
				if (bin.byteLength === 32) mode = "full";

        let tick = {
          tradable: tradable,
          mode: mode,
          instrument_token: instrument_token,
          last_price: this.buf2long(bin.slice(4,8)) / divisor,
          ohlc: {
            high: this.buf2long(bin.slice(8, 12)) / divisor,
            low: this.buf2long(bin.slice(12, 16)) / divisor,
            open: this.buf2long(bin.slice(16, 20)) / divisor,
            close: this.buf2long(bin.slice(20, 24)) / divisor
					},
					change: this.buf2long(bin.slice(24, 28))
				};

        // Compute the change price using close price and last price
        if (tick.ohlc.close != 0) {
          tick.change = (tick.last_price - tick.ohlc.close) * 100 / tick.ohlc.close;
				}

        // Full mode with timestamp in seconds
        if (bin.byteLength === 32) {
					tick.exchange_timestamp = null;
					let timestamp = this.buf2long(bin.slice(28, 32));
					if (timestamp) tick.exchange_timestamp = new Date(timestamp * 1000);
				}

				ticks.push(tick);

			} else if (bin.byteLength === 44 || bin.byteLength === 184) {
				let mode = "quote";
				if (bin.byteLength === 184) mode = "full";

				let tick = {
          tradable: tradable,
          mode: mode,
          instrument_token: instrument_token,
          last_price: this.buf2long(bin.slice(4, 8)) / divisor,
          last_traded_quantity: this.buf2long(bin.slice(8, 12)),
          average_traded_price: this.buf2long(bin.slice(12, 16)) / divisor,
          volume_traded: this.buf2long(bin.slice(16, 20)),
          total_buy_quantity: this.buf2long(bin.slice(20, 24)),
          total_sell_quantity: this.buf2long(bin.slice(24, 28)),
          ohlc: {
            open: this.buf2long(bin.slice(28, 32)) / divisor,
            high: this.buf2long(bin.slice(32, 36)) / divisor,
            low: this.buf2long(bin.slice(36, 40)) / divisor,
            close: this.buf2long(bin.slice(40, 44)) / divisor
          }
				};

        // Compute the change price using close price and last price
        if (tick.ohlc.close != 0) {
          tick.change = (tick.last_price - tick.ohlc.close) * 100 / tick.ohlc.close;
				}

				// Parse full mode
				if (bin.byteLength === 184) {
					// Parse last trade time
					tick.last_trade_time = null;
					let last_trade_time = this.buf2long(bin.slice(44, 48));
					if (last_trade_time) tick.last_trade_time = new Date(last_trade_time * 1000);

					// Parse timestamp
					tick.exchange_timestamp = null;
					let timestamp = this.buf2long(bin.slice(60, 64));
					if (timestamp) tick.exchange_timestamp = new Date(timestamp * 1000);

					// Parse OI
					tick.oi = this.buf2long(bin.slice(48, 52));
          tick.oi_day_high = this.buf2long(bin.slice(52, 56));
					tick.oi_day_low = this.buf2long(bin.slice(56, 60));
					tick.depth = {
						buy: [],
						sell: []
					};

					let s = 0;
          let depth = bin.slice(64, 184);

					for (let i = 0; i < 10; i++) {
						s = i * 12;
						tick.depth[i < 5 ? "buy" : "sell"].push({
							quantity: this.buf2long(depth.slice(s, s + 4)),
							price: this.buf2long(depth.slice(s + 4, s + 8)) / divisor,
							orders: this.buf2long(depth.slice(s + 8, s + 10))
						});
					}
				}

				ticks.push(tick);
			}
		}

		return ticks;
  }

  splitPackets = bin => {
    // number of packets
		let num = this.buf2long(bin.slice(0, 2));
		let j = 2;
		let packets = [];

		for (let i = 0; i < num; i++) {
			// first two bytes is the packet length
			let size = this.buf2long(bin.slice(j, j + 2));
			let packet = bin.slice(j + 2, j + 2 + size);

			packets.push(packet);

			j += 2 + size;
		}

		return packets;
  }

  buf2long = buf => {
    let b = new Uint8Array(buf);
		let val = 0;
		let len = b.length;

		for (let i = 0, j = len - 1; i < len; i++, j--) {
			val += b[j] << (i * 8);
		}

		return val;
  }

  render() {
    let page = null;

    if (!this.state.loggedIn) {
      page = <LoginForm
        handleLogin={this.handleLogin}
      />

    } else {
      page = <MainPage
        URL={this.URL}
        username={this.state.username}
        handleLogout={this.handleLogout}

        settings={this.state.settings}
        handleSettingsSave={this.handleSettingsSave}
        handleSettingsChange={this.handleSettingsChange}

        niftyData={this.state.niftyData}
        bankniftyData={this.state.bankniftyData}
        finniftyData={this.state.finniftyData}
      />
    }

    return (
      <div className="App">
        {page}
      </div>
    );
  }
}

export default App;

      // let reader = new FileReader();
      //
      // reader.onload = function () {
      //   console.log(reader.result);
      // }
      //
      // reader.readAsBinaryString(e.data);

      // console.log(JSON.parse(await e.data.arraybuffer().buffer.toJSON()));

      // let data = new Uint8Array(e.data);
      // console.log(data);
      //
      // console.log(data.buffer.toJSON());

      // // const raw = Buffer.from(e.data.slice(1), "base64");
      // console.log(this.MarketData.read(data.slice(1)));
      // console.log(new TextDecoder().decode(data.slice(1)));

      // console.log(bufferify.encode(0, {
      //   obj: {
      //     opcode: 8,
      //     info: 'Hello'
      //   },
      //   arr: [1, 2, 3, 4, 5],
      //   list: [{
      //     name: 'Jerry',
      //     id: '536598'
      //   },
      //   {
      //     name: 'Tom',
      //     id: '85947'
      //   },
      //   {
      //     id: '459823',
      //     name: 'Kevin'
      //   }]
      // }));

      // console.log(bufferify.decode(0, {
      //   exchange: "string",
      //   token: "uint32",
      //   ltp: "uint32",
      //   ltt: "number",
      //   ltq: "number",
      //   volume: "number",
      //   best_bid_price: "number",
      //   best_bid_quantity: "number",
      //   best_ask_price: "number",
      //   best_ask_quantity: "number",
      //   total_buy_quantity: "number",
      //   total_sell_quantity: "number",
      //   atp: "number",
      //   exchange_time_stamp: "number",
      //   open: "number",
      //   high: "number",
      //   low: "number",
      //   close: "number",
      //   yearly_high: "number",
      //   yearly_low: "number"
      // }, e.data));
      //
      // console.log(JSON.stringify(Array.from(data)));
      //
      // const enc = new TextDecoder("utf-8");
      // console.log(JSON.parse(await e.data.text()));

      // const reader = new FileReader();
      // console.log(reader.readAsText(e.data.text()));

      // let data = await e.data.text();
      // console.log(JSON.parse(data));

      // const reader = new FileReader();
      // reader.addEventListener('loadend', () => {
      //    // reader.result contains the contents of blob as a typed array
      //    console.log(reader.result);
      // });
      // reader.readAsArrayBuffer(e.data);
      //
      // console.log(await (new Response(e.data)).text());
      //
      // const fr = new FileReader();
      //
      // fr.onload = (e) => {
      //   console.log(JSON.parse(e.target.result))
      // };
      //
      // fr.readAsText(e.data);
