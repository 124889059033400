import { Component } from "react";

class LoginForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: ""
    }
  }

  handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(prevstate => {
      const newState = { ...prevstate };
      newState[name] = value;
      return newState;
    });
  }

  handleLoginFormSubmit = e => {
    e.preventDefault();
    this.props.handleLogin(this.state);
  }

  render() {
    return (
      <div className="columns is-mobile">
        <div className="column is-three-fifths-tablet is-offset-one-fifth-tablet is-full-mobile mt-2">
          <div className="card is-primary">
            <header className="card-header">
              <h2 className="card-header-title">Login Form</h2>
            </header>

            <section className="card-content">
              <form onSubmit={this.handleLoginFormSubmit} id="login-form">
                <div className="field">
                  <label className="label" htmlFor="username-input">Username:</label>
                  <div className="control">
                    <input
                      required
                      autoFocus
                      type="text"
                      name="username"
                      id="username-input"
                      placeholder="Username"
                      className="input is-info"
                      value={this.state.username}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                <div className="field">
                  <label className="label" htmlFor="password-input">Password:</label>
                  <div className="control">
                    <input
                      required
                      autoFocus
                      type="password"
                      name="password"
                      id="password-input"
                      placeholder="Password"
                      className="input is-info"
                      value={this.state.password}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </form>
            </section>

            <footer className="card-footer">
              <button className="card-footer-item button is-primary" type="submit" form="login-form">
                <span>Login</span>
                <span className="icon"><i className="fas fa-sign-in-alt"></i></span>
              </button>
            </footer>
          </div>
        </div>
      </div>
    );
  }
}

export default LoginForm;
