import { Component } from "react";

class Settings extends Component {
  handleSettingsSave = async e => {
    e.preventDefault();
    await this.props.handleSettingsSave();
  }

  render() {
    return (
      <div>
        <div className="columns is-mobile">
          <div className="column is-three-fifths-tablet is-offset-one-fifth-tablet is-full-mobile mt-2">
            <div className="card m-2">
              <div className="card-header">
                <h2 className="card-header-title">Strategy Settings</h2>
              </div>

              <div className="card-content">
                <form onSubmit={this.handleSettingsSave} className="m-2" id="settings-form">
                  <div className="field">
                    <label htmlFor="nifty-expiry" className="label">Nifty Expiry</label>
                    <div className="control">
                      <input
                        type="date"
                        id="nifty-expiry"
                        name="niftyExpiry"
                        className="input is-info"
                        value={this.props.settings.niftyExpiry}
                        onChange={this.props.handleSettingsChange}
                      />
                    </div>
                  </div>

                  <div className="field">
                    <label htmlFor="banknifty-expiry" className="label">Banknifty Expiry</label>
                    <div className="control">
                      <input
                        type="date"
                        id="banknifty-expiry"
                        name="bankniftyExpiry"
                        className="input is-info"
                        value={this.props.settings.bankniftyExpiry}
                        onChange={this.props.handleSettingsChange}
                      />
                    </div>
                  </div>

                  <div className="field">
                    <label htmlFor="finnifty-expiry-type" className="label">Finnifty Expiry Type</label>
                    <div className="control">
                      <input
                        type="date"
                        id="finnifty-expiry"
                        name="finniftyExpiry"
                        className="input is-info"
                        value={this.props.settings.finniftyExpiry}
                        onChange={this.props.handleSettingsChange}
                      />
                    </div>
                  </div>
                </form>
              </div>

              <footer className="card-footer">
                <button className="card-footer-item button is-link" type="submit" form="settings-form">Save Settings</button>
              </footer>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Settings;
