import { Component } from "react";

class Navbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMenuOpen: false
    }
  }

  handleBurgerClick = () => {
    this.setState(prevState => {
      const newState = { ...prevState };
      newState.isMenuOpen = !newState.isMenuOpen;
      return newState;
    });
  }

  render() {
    return (
      <nav className="navbar is-dark" role="navigation">
        <div className="navbar-brand">
          <span className="navbar-item">Hi, {this.props.username}</span>
          <a role="button" className={this.state.isMenuOpen ? "navbar-burger is-active" : "navbar-burger"} onClick={this.handleBurgerClick} href="/#">
            <span></span>
            <span></span>
            <span></span>
          </a>
        </div>

        <div className={this.state.isMenuOpen ? "navbar-menu is-active" : "navbar-menu"}>
          <div className="navbar-start">
            <a href="/#" className="navbar-item" onClick={() => this.props.changePage("dashboard")}>Dashboard</a>
            <a href="/#" className="navbar-item" onClick={() => this.props.changePage("settings")}>Settings</a>
          </div>

          <div className="navbar-end">
            <div className="navbar-item">
              <div className="buttons">
                <button className="button is-danger" onClick={this.props.handleLogout}>
                  <span>Logout</span>
                  <span className="icon"><i className="fas fa-sign-out-alt"></i></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navbar;
